<script lang="ts">
export default { name: 'AccountLayout' };
</script>

<script setup lang="ts">
import { AccountSidebar, Icon } from '@upa/design-system';
const { t } = useI18n();

// Navigation for Account page
const { loadNavigationElements } = useNavigation();
const router = useRouter();
const { logout } = useUser();
// const router = useRouter();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
const { successAfterLogout } = useAuth();

const { data } = await useAsyncData('mainNavigation', () => {
  return loadNavigationElements({ depth: 2 });
});
provide('swNavigation-main-navigation', data);

const { loadNavigationElements: loadFooterNavigationElements } = useNavigation({
  type: 'footer-navigation',
});
const { data: footerData } = await useAsyncData('mainFooterNavigation', () => {
  return loadFooterNavigationElements({ depth: 2 });
});

async function invokeLogout() {
  await logout();
  router.push(formatLink('/login'));
  successAfterLogout();
}

provide('swNavigation-footer-navigation', footerData);

const headerHeadline = computed(() => {
  const currentPath = router.currentRoute.value.fullPath;
  const lastPathSegment = currentPath.split('/').pop();

  if (lastPathSegment === 'order') {
    return t('account.sidebar.content.orders');
  }

  if (lastPathSegment === 'profile') {
    return t('account.sidebar.content.profile');
  }

  if (lastPathSegment === 'address') {
    return t('account.sidebar.content.addresses');
  }
});
</script>

<template>
  <div>
    <LayoutHeader :header-headline="headerHeadline" />
    <LayoutNotifications />
    <div class="account-layout">
      <div>
        <div class="account-inner">
          <div class="account-layout__container">
            <aside class="account-layout__side" aria-label="Sidebar">
              <AccountSidebar
                :title="$t('account.sidebar.title')"
                :button-label-logout="$t('account.sidebar.logout')"
                :button-label-go-to-home="$t('account.sidebar.goToHome')"
                @logout="invokeLogout"
                @go-to-home="router.push(formatLink('/'))"
              >
                <template #content>
                  <ul>
                    <li>
                      <NuxtLink
                        :to="formatLink(`/account/profile`)"
                        class="account-sidebar__link"
                      >
                        <Icon name="arrow_right" />
                        <span class="account-sidebar__link-text">{{
                          $t('account.sidebar.content.profile')
                        }}</span>
                      </NuxtLink>
                    </li>
                    <li>
                      <NuxtLink
                        :to="formatLink(`/account/address`)"
                        class="account-sidebar__link"
                      >
                        <Icon name="arrow_right" />
                        <span class="account-sidebar__link-text">{{
                          $t('account.sidebar.content.addresses')
                        }}</span>
                      </NuxtLink>
                    </li>
                    <li>
                      <NuxtLink
                        :to="formatLink(`/account/order`)"
                        class="account-sidebar__link"
                      >
                        <Icon name="arrow_right" />
                        <span class="account-sidebar__link-text">{{
                          $t('account.sidebar.content.orders')
                        }}</span>
                      </NuxtLink>
                    </li>
                  </ul>
                </template>
              </AccountSidebar>
            </aside>
            <main class="account-layout__main">
              <slot />
            </main>
          </div>
        </div>
      </div>
    </div>

    <LayoutFooter />
  </div>
</template>
